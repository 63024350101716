import { Text } from "@biblioteksentralen/react";
import { FrontendLocale, LocalizedField, translateSanity } from "@libry-content/localization";
import { HeaderFooterContainer } from "../ContentContainer";
import InternalLink from "../InternalLink";
import { PageHeader } from "../layout/PageHeader";
import { errorPageTranslations } from "./errorPageTranslations";

export const ErrorPageSiteHeader = ({
  siteName,
  languageCode,
}: {
  siteName: LocalizedField<string>;
  languageCode: FrontendLocale;
}) => {
  const name = translateSanity(siteName, languageCode) ?? "";
  const [firstPartOfName, ...remainingName] = name.split(" ");

  return (
    <PageHeader>
      <HeaderFooterContainer padding="1rem 1.75rem" fontSize="0.9rem" gridGap="2rem">
        <InternalLink href="/" textTransform="uppercase">
          {firstPartOfName ? (
            <>
              <Text as="span" fontWeight="semibold">
                {firstPartOfName}
              </Text>{" "}
              <Text as="span">{remainingName.join(" ")}</Text>
            </>
          ) : (
            errorPageTranslations[languageCode]["tilbake"]
          )}
        </InternalLink>
      </HeaderFooterContainer>
    </PageHeader>
  );
};
