import { Box, colors, HStack, Text } from "@biblioteksentralen/react";
import { AlertCircle } from "react-feather";
import { useTranslation } from "../../utils/hooks/useTranslation";

export const InlineError = (props: { boundaryName?: string }) => {
  const { t } = useTranslation();

  return (
    <HStack
      fontWeight="semibold"
      color={colors.statusRed}
      background={colors.alertRed}
      borderRadius="0.25rem"
      padding="0.5rem 1rem"
      gap="0.5rem"
    >
      <AlertCircle />
      <Box>
        <Text>{t("Ops, noe gikk galt")}</Text>
        <Text fontSize="2xs" opacity={0.7}>
          {props.boundaryName}
        </Text>
      </Box>
    </HStack>
  );
};
