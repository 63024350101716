import { Component, ErrorInfo, ReactNode } from "react";
import ErrorPage from "../../pages/500";
import { InlineError } from "./ErrorComponent";

interface Props {
  boundaryName?: string;
  isFullPage?: boolean;
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error?: Error;
  errorInfo?: ErrorInfo;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ hasError: true, error, errorInfo });
    console.error(error, { errorInfo, boundaryName: this.props.boundaryName });
  }

  // We don't render stack trace etc even in development; this is taken care of automatically by next
  render() {
    if (this.state.hasError && this.props.isFullPage) return <ErrorPage />;

    if (this.state.hasError) return <InlineError boundaryName={this.props.boundaryName} />;

    return <>{this.props.children}</>;
  }
}

export default ErrorBoundary;
