import { Modify } from "@biblioteksentralen/types";
import { Event } from "@libry-content/types";
import groq from "groq";

export const groqEventStart = groq`select(allDay => '00:00', eventStart)`;

// By performing the flattening in the query, we can use groq's ordering and pagination.
// Note that as is this can not be used where the events are to be filtered, as in the website frontend.
export const resolveRepeatedEvents = (repeatedDateFilter = "") => groq`
  {"occurrences": select(
    repeated => repeatedDates[${repeatedDateFilter}] {
      ...^, // Spread the parent event
      "promotion": null, // Erase parent promotion
      "repeatedDates": null, // Erase repeated dates array to avoid confusion
      "repeated": null, // Erase repeated flag to avoid confusion
      ...customization, // Spread the single date customization
      "startDate": date, // Single date
      "series": {"id": ^._id}, // Series id from the parent event
      "_id": _key, // Single date key as id
      "_type": "resolvedRepeatedEventOccurence" // Use unique type to distinguish from event-document
    },
    [@] // Non-repeated event
  )}
  .occurrences[] // Flatten
 `;

export type ResolvedRepeatedEventOccurence = Modify<
  Omit<Event, "repeatedDates" | "repeated">,
  {
    series: { id: string };
    _type: "resolvedRepeatedEventOccurence";
  }
>;

export const isRepeatedEventOccurence = <T extends ResolvedRepeatedEventOccurence>(doc: unknown): doc is T =>
  (doc as ResolvedRepeatedEventOccurence)?._type === "resolvedRepeatedEventOccurence";

export const isEvent = <T extends Event>(doc: unknown): doc is T => (doc as Event)?._type === "event";
