import { FrontendLocale } from "@libry-content/localization";

const textStrings = [
  "Filler'n!",
  "Gå til forsiden",
  "Dette var uventet.",
  "Noe har gått galt. Ha litt tålmodighet med oss, så løser det seg nok snart.",
  "Feilkode 500",
  "tilbake",
] as const;

type TextString = (typeof textStrings)[number];

type Translation = Record<TextString, string>;

const nnTranslations: Translation = {
  "Gå til forsiden": "Gå til framsida",
  "Filler'n!": "Filler'n!",
  "Dette var uventet.": "Dette var uventa.",
  "Noe har gått galt. Ha litt tålmodighet med oss, så løser det seg nok snart.":
    "Noko gjekk gale. Ha litt tolmod med oss, så løyser det seg nok snart.",
  "Feilkode 500": "Feilkode 500",
  tilbake: "tilbake",
};

/**
 * We can't use next-translate in error pages: https://github.com/i18next/next-i18next/issues/677
 */
export const errorPageTranslations: Record<FrontendLocale, Translation> = {
  nn: nnTranslations,
  nb: textStrings.reduce((acc, textString) => ({ ...acc, [textString]: textString }), {}) as Translation,
};
